import { Injectable } from '@angular/core';

import { CurrentUserService } from '@common/models/current-user.service';

@Injectable()
export class LoginMonitorService {
  constructor(private currentUserService: CurrentUserService) {}

  start() {
    const loginTime = '' + Date.now();
    localStorage.setItem('pl-login-time', loginTime);
    setInterval(() => {
      const timeCheck = localStorage.getItem('pl-login-time');
      if (timeCheck !== loginTime) {
        console.log('Double login detected, logging out');
        this.currentUserService.logout();
      }
    }, 1000);
  }
}
